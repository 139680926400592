@font-face {
    font-family: 'Alliance-Regular';
    src: url('assets/fonts/sch/AllianceNo.2-Regular.otf') format('opentype');
}

@font-face {
    font-family: 'Alliance-Meduim';
    src: url('assets/fonts/sch/ALLIANCENO.2-MEDIUM.OTF') format('opentype');
}

@font-face {
    font-family: 'Alliance-Bold';
    src: url('assets/fonts/sch/AllianceNo.2-Bold.otf') format('opentype');
}

@font-face {
    font-family: 'Alliance-Italic';
    src: url('assets/fonts/sch/AlliancePlatt-RegularItalic.otf') format('opentype');
}