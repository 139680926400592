/* You can add global styles to this file, and also import other style files */
:root{
  // --background-color: #F5F5F5;
  --background-color: #000014;
  --default-color: #000;
  // --primary-color: #8d8d8d;
  --primary-color: #FFFFFF;
  --secondary-color: #FFAAFF ;
  --disabled-color: #ABABAB;
  --dark-body-color: #FFFFFF;
}



/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
$body-color:#FFFFFF;
$bg-color: #000014;
@import '~bootstrap/scss/bootstrap';

/* Other imports */
@import '~@fortawesome/fontawesome-free/css/all.min.css';

@import 'fonts.scss';

html, body { height: 100%; }
body {
  margin: 0;
  font-family: 'Alliance-Regular', "Helvetica Neue", sans-serif;
  background-color: var(--background-color)! important;
  overflow: auto;
}

a{
  color: var(--primary-color) !important;
}
.title-header{
  text-align: left;
  color: var(--primary-color);

  h1, h2, h3, h4, h5, h6{
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.3em;
    // color: var(--primary-color)!important;
  }
}

.modal-content{
  width: fit-content;
  height: fit-content;
  margin: auto;
}
.modal-dialog {
  max-width: 95vw !important;
  margin: auto !important;
}

@media (min-width: 576px)
{
  .modal-dialog {
    max-width: 95vw !important;
    margin: auto !important;
  }
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: var(--primary-color) !important;
}

.container-fluid {
  padding-bottom: 20px;
}